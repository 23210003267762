import React, { ReactNode, useEffect, useRef, useState, RefObject } from 'react'
import Carousel from 'react-multi-carousel'
import ArrowLeft from 'inter-frontend-svgs/lib/v2/navigation/chevron-left'
import ArrowRight from 'inter-frontend-svgs/lib/v2/navigation/chevron-right'

import RightArrow from './RightArrow'
import LeftArrow from './LeftArrow'

import useWidth from 'src/hooks/window/useWidth'

import 'react-multi-carousel/lib/styles.css'

import { Container, ProgressBar, Counter } from './style'

type ItemsProps = {
  items: number;
  slidesToSlide: number;
  partialVisibilityGutter: number;
}

type HeaderCarouselProps = {
  children: ReactNode[];
  sm: ItemsProps;
  md: ItemsProps;
  lg: ItemsProps;
  xl: ItemsProps;
  containerClass?: string;
}

function HeaderCarousel ({ children, sm, md, lg, xl, containerClass }: HeaderCarouselProps) {
  const CarouselRef = useRef<Carousel>()
  const [ progress, setProgress ] = useState(0)
  const [ currentSlider, setCurrentSlider ] = useState(0)
  const windoWidth = useWidth(300)

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 3000, min: 1200 },
      items: xl.items,
      slidesToSlide: xl.slidesToSlide,
      partialVisibilityGutter: xl.partialVisibilityGutter,
    },
    desktop: {
      breakpoint: { max: 1199, min: 992 },
      items: lg.items,
      slidesToSlide: lg.slidesToSlide,
      partialVisibilityGutter: lg.partialVisibilityGutter,
    },
    tablet: {
      breakpoint: { max: 991, min: 768 },
      items: md.items,
      slidesToSlide: md.slidesToSlide,
      partialVisibilityGutter: md.partialVisibilityGutter,
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: sm.items,
      slidesToSlide: sm.slidesToSlide,
      partialVisibilityGutter: sm.partialVisibilityGutter,
    },
  }

  useEffect(() => {
    CarouselRef.current.goToSlide(0)
  }, [ windoWidth ])

  function moveProgressBar (nextSlide: number) {
    setCurrentSlider(nextSlide)
    setProgress(((nextSlide + 1) / children.length) * 100)
  }

  function formatNumberToTwoDigits (number: number) {
    return number.toLocaleString('pt-BR', { minimumIntegerDigits: 2 })
  }

  return (
    <Container>
      <Carousel
        ref={CarouselRef as RefObject<Carousel>}
        responsive={responsive}
        customRightArrow={<RightArrow />}
        customLeftArrow={<LeftArrow />}
        partialVisible={true}
        showDots={false}
        arrows={true}
        containerClass={containerClass}
        beforeChange={moveProgressBar}
      >
        {children}
      </Carousel>
      <button disabled type='button' className='border-0 rounded-100 react-multiple-carousel__arrow react-multiple-carousel__arrow--left'>
        <ArrowLeft width={20} height={20} color='white' />
      </button>
      <ProgressBar progress={progress}><span /></ProgressBar>
      <button disabled type='button' className='border-0 rounded-100 react-multiple-carousel__arrow react-multiple-carousel__arrow--right'>
        <ArrowRight width={20} height={20} color='white' />
      </button>
      <Counter className='text-grayscale--400 fs-12 fs-sm-14'>{formatNumberToTwoDigits(currentSlider + 1)} / {formatNumberToTwoDigits(children.length)}</Counter>
    </Container>
  )
}

HeaderCarousel.defaultProps = {
  sm: {
    items: 1,
    slidesToSlide: 1,
    partialVisibilityGutter: 0,
  },
  md: {
    items: 1,
    slidesToSlide: 1,
    partialVisibilityGutter: 0,
  },
  lg: {
    items: 1,
    slidesToSlide: 1,
    partialVisibilityGutter: 0,
  },
  xl: {
    items: 1,
    slidesToSlide: 1,
    partialVisibilityGutter: 0,
  },
}

export default HeaderCarousel
