import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import BackgroundImage from 'gatsby-background-image'
import { grayscale } from 'src/styles/colors'

export const Wrapper = styled.div`
  h1, h2, h3, h4, h5 {
    font-family: 'Sora';
  }

  h2 {
    margin-bottom: 20px;
  }
`

export const ContentHeader = styled.section`
  align-items: center;
  display: flex;
  min-height: 100vh;

  .breadcrumb {
    span, a {
      color: ${grayscale[500]} !important;
    }

    .bread {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    @media ${device.desktopXL} {
      position: relative;
      top: 20px;
    }
  }

  @media ${device.tablet} {
    min-height: 400px;
  }
  @media ${device.desktopLG} {
    min-height: 550px;
  }
  @media ${device.desktopXXL} {
    min-height: calc(100vh - 73px);
  }

  >.container {

    .react-multi-carousel-track {
      padding-top: 0;
      padding-bottom: 60px;

      @media ${device.tablet} {
        padding-bottom: 30px;
      }
      @media ${device.desktopXL} {
        padding-bottom: 0;
      }
      .react-multi-carousel-item {
        button.btn, a.btn {
          @media ${device.desktopLG} {
            height: 44px;
          }
        }
      }
    }
  }
`

export const EnjoyTheAdvantagesSection = styled.section`
  position: relative;
  @media ${device.tablet} {
    height: 408px;
  } 
  @media ${device.desktopLG} {
    height: 451px;
  }  
  @media ${device.desktopXL} {
    height: 640px;
  }  
`

export const EnjoyTheAdvantagesBackground = styled(BackgroundImage)`
  width: 100%;
  height: 409px;
  margin-bottom: -30px;

  @media ${device.tablet} {
    width: 492px;
    height: 408px;
    background-position: top left;
    position: absolute !important;
    margin-bottom: 0 !important;
  }

  @media ${device.desktopLG} {
    width: 642px;
    height: 451px;
  }
  @media ${device.desktopXL} {
    width: 850px;
    height: 640px;
  }
`

export const BoxCard = styled.div`

  .mude-pro-nter {
    min-height: 158px;
  }

  @media ${device.tablet} {
    
    &.bg-white {
      background: none !important;
      border: none !important;
      min-height: 190px;

      &.mude-pro-nter {
        min-height: 205px;
      }     
    }

    .bg-white {

      min-height: 200px;
      
      &.mude-pro-nter {
        min-height: 205px;
      }
      &.voce-por-dentro {
        min-height: 140px;
      }
    }
  }

  @media ${device.desktopLG} {
    .bg-white {
      min-height: 230px;
    }
  }

  @media ${device.desktopXL} {    
    .bg-white {
      min-height: 280px;

      &.mude-pro-nter {
        min-height: 230px;
      }
    }
  }

  .collapse {
    padding: 0 5px 0 15px;

    .collapse-content {
      padding: 0;

      .arrow {
        margin-top: 8px;

        &--open {
          filter: initial;
        }
      }    
    }
    &.collapse--open {

      h3 {
        margin-top: 10px;
      }
      .details {

       .details-content {
          padding-left: 0;
          padding-right: 5px;
        }
      }
    }
  } 
`
export const ImgSize = styled.div`
  .gatsby-image-wrapper {
    @media ${device.tablet} {
      width: 336px;
    }
    @media ${device.desktopLG} {
      width: 376px;
    }
    @media ${device.desktopXL} {
      width: 456px;
      
    }
  }
`

export const CreateYourCommunitySection = styled.section`

  .title, .question, .step {
    font-family: 'Sora';
  }
  min-height: 736px;

  @media ${device.tablet} {
    min-height: 504px;
  }
  @media ${device.desktopXL} {
    min-height: 752px;
  }

  .content-text {
    min-height: 157px;
    @media ${device.tablet} {
      min-height: 140px;
    }
  }
`
