import { useStaticQuery, graphql } from 'gatsby'

const PageQuery = () => {
  const pageQuery = useStaticQuery(graphql`
    query {
      turbineSeuCashback: imageSharp(fluid: {originalName: { regex: "/turbine-seu-cashback-na-sua-comunidade/" }}) {
        fluid(maxWidth: 457, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      junteSeusAmigos: imageSharp(fluid: {originalName: { regex: "/junte-seus-amigos/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      enjoyTheAdvantagesSM: imageSharp(fluid: {originalName: { regex: "/aproveite-as-vantagens-sm/" }}) {
        fluid(maxWidth: 494, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      enjoyTheAdvantagesMD: imageSharp(fluid: {originalName: { regex: "/aproveite-as-vantagens-md/" }}) {
        fluid(maxWidth: 684, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      enjoyTheAdvantagesLG: imageSharp(fluid: {originalName: { regex: "/aproveite-as-vantagens-lg/" }}) {
        fluid(maxWidth: 760, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      enjoyTheAdvantagesXL: imageSharp(fluid: {originalName: { regex: "/aproveite-as-vantagens-xl/" }}) {
        fluid(maxWidth: 850, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      iconTwitter: imageSharp(fluid: {originalName: { regex: "/icon-twitter/" }}) {
        fixed(width: 24, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
      iconSpotify: imageSharp(fluid: {originalName: { regex: "/icon-spotify/" }}) {
        fixed(width: 24, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
      iconSearch: imageSharp(fluid: {originalName: { regex: "/icon-search/" }}) {
        fixed(width: 24, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
      criarComunidade: imageSharp(fluid: {originalName: { regex: "/criar/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      acessarInvestimentos: imageSharp(fluid: {originalName: { regex: "/para-comecar/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      escolhaUmNome: imageSharp(fluid: {originalName: { regex: "/escolher-nome/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      convideAmigos: imageSharp(fluid: {originalName: { regex: "/convide-amigos/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      atividades: imageSharp(fluid: {originalName: { regex: "/atividades/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  `)

  return pageQuery
}

export default PageQuery
